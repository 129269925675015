@font-face {
  font-family: 'Verdana';
  src: url('../fonts/Verdana.woff') format('woff'),
  url('../fonts/Verdana.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Verdana';
  src: url('../fonts/Verdana-Bold.woff') format('woff'),
  url('../fonts/Verdana-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Verdana';
  src: url('../fonts/Verdana-Italic.woff') format('woff'),
  url('../fonts/Verdana-Italic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}


// Moves the body down 32px when the wp admin toolbar is shown
body.customize-support {
  padding-top: 32px;
}

*, *:after, *:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}

html, body {
  min-height: 100vh;
  font-size: 12px;
  background-color: $white;
  color: $text;
  font-family: $body-font;
}

blockquote {
  font-size: 1.2rem;
  margin: 52px;
  margin-bottom: 0;
  text-align: center;
  font-weight: 700;
  font-style: italic;
  @include xsmall{
    margin: $base-spacing 0 0 0;
  }
}


ol {
  margin: $base-spacing $base-spacing $base-spacing*2 $base-spacing;
}

ul {
  margin: $base-spacing;
  list-style: none;

  li {
    &:before {
      /*
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;

      color: $color-blue;
      margin-left: -$base-spacing/2;
      margin-top: 5px;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f111";
      font-size: 6px;
      position: relative;
      padding-right: 10px;
      */
    }

    margin-bottom: $base-spacing/2;
  }

  ul {
    color: $color-aqua;

    li {
      &:before {
        color: $color-aqua;
      }
    }
  }
}

p {
  margin-bottom: $base-spacing / 1.5;
  font-size: $font-size-body;
}
li {
  font-size: $font-size-body;
}
.text-aqua {
  color: $color-aqua;
}
.inner-wrapper {
    max-width: 1200px;
    margin: auto;
    padding: 0;
    color: $text;
}
.page-constraint {
  max-width: 1200px;
  margin: auto;
  color: $text;
  padding: $base-spacing;
  overflow-x: auto;
  overflow-y: hidden;

  &.no-vert-padding {
    padding-top: 0;
    padding-bottom: 0;
  }

  @include xsmall{
    padding: $base-spacing/2;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-size: $font-size-header;
  margin: 0;
  margin-bottom: $base-spacing;
  color: $text;
  font-family: $title-font;
  font-weight: 400;

  * {
    //font-family: 'MetaPro', sans-serif;
  }

  &.subheading {
    font-size: $font-size-sub-header;
    padding: $base-spacing/2 0;
  }
  @include small{
    font-size: $font-size-header-mobile-medium;
  }
}

.alt-font {
   font-family: $body-font;
}
h1, h2 {
  color: $primary-colour;
}
h3, h4, h5, h6 {
  font-size: 26px;
  margin-bottom: $base-spacing/3*2;
}
h1 {
  font-size: $font-size-header;
}
img {
  width: 100%;
  height: auto;
  vertical-align: bottom;

  &.circled-blue {
    //border: 4px solid $color-blue;
  }
}

p {
  line-height: 1.8rem;
}
.small {
  font-size: $font-size-body-small;
}
hr {
  margin: ($base-spacing * 2) 0 $base-spacing 0;
}

a {
  text-decoration: none;
  transition: color $animation-fade;
  color: $text;




  &.dark-grey {
    color: $color-dark-grey;
    border-bottom: 2px solid;
    border-color: transparent;
    transition: border-color $animation-fade;

    &:visited {
      color: $color-dark-grey;
    }

    &:hover,
    &:active {
      color: $color-dark-grey;
      border-color: $color-dark-grey;
    }
  }

  &.orange {
    color: $color-orange;

    &:visited {
      color: $color-orange;
    }

    &:hover,
    &:active {
      color: $color-orange-dark;
    }
  }

  &.turquoise {
    color: $color-turquoise;

    &:visited {
      color: $color-turquoise;
    }

    &:hover,
    &:active {
      color: $color-turquoise-dark;
    }
  }
}

sub {
  font-size: 11px;
  font-weight: 400;
  top: -$base-spacing/2;
}
table, th, td {
  border: 1px solid $primary-colour-transparent;
}
table{
  width: 100%;
  overflow-x: auto;
  border-collapse: collapse;
  margin-bottom: $base-spacing;
}
th {
  background: $primary-colour;
  color: $primary-colour-text;
  padding: $base-spacing / 2;
  font-size: $font-size-body-large;
  text-align: left;
  font-family: $title-font;
}
.heading-row {
  td {
    font-size: $font-size-body-large;
    font-weight: 700;
    font-family: $title-font;
    }
 }
td {
  padding: $base-spacing / 2;
  font-size: $font-size-body;
}
.overlay {
    inset: 0;
    position: absolute;
    background: $overlay;
    z-index: 50;
  }
  .overlay-dark {
    background: $overlay-dark;
  }
.standard-link {
  text-transform: uppercase;
  color: $color-dark-grey;
  font-size: 12px;
  &:hover {
    text-decoration: underline;
    color: $color-dark-grey;
  }
}
.external-link-icon {
  width: 24px;
  height: 24px;
  margin-left: 7px;
  position: relative;
  top: 7px;
  background-image: url('/wp-content/themes/ryeqo/custom/images/external.svg');
  display: inline-block;
}
.button-container {
    text-align: center;
  }
.button {
  font-size: $font-size-button;
  display: inline-block;
  margin: $base-spacing/2 0;
  padding: $base-spacing/3 $base-spacing/2;
  text-transform: uppercase;
  transition: color $animation-fade, border $animation-fade, background-color $animation-fade;

  &.button-white {
    padding: $base-spacing / 2;
    border: 2px solid $primary-colour;
    font-size: $font-size-button;
    display: inline-block;
    margin-bottom: $base-spacing / 2;
    &:hover {
      background-color: $primary-colour;
      color: $primary-colour-text;
      transition: all 0.3s;
    }
  }

  &.button-white-bg-blue {
    color: $white;
    border-color: $color-blue;
    background-color: $color-blue;

  }

  &.button-white-bg-aqua {
    color: $white;
    border-color: $color-aqua;
    background-color: $color-aqua;
  }

  &.button-border-aqua-color-blue-bg-transparent {
    color: $color-blue;
    background-color: transparent;
    border-color: $color-aqua;

    &:visited {
      color: $color-aqua;
      background-color: transparent;
      border-color: $color-blue;
    }
  }

  &.button-border-aqua-white-bg-transparent {
    color: $white;
    background-color: transparent;
    border-color: $color-aqua;

    &:visited {
      color: $white;
      background-color: transparent;
      border-color: $color-blue;
    }
  }

  &.button-color-dark-grey-bg-white {
    color: $color-dark-grey;
    background-color: $white;
    border-color: $white;

    &:visited {
      color: $color-dark-grey;
      background-color: $white;
      border-color: $white;
    }
  }

  &.button-white-dark-grey-border {
    color: $color-dark-grey;
    border-color: $color-dark-grey;

    &:visited {
      color: $color-dark-grey;
      border-color: $color-dark-grey;
    }
  }

  &.button-dark-grey {
    color: $white;
    border-color: $color-dark-grey;
    background-color: $color-dark-grey;

    &:visited {
      color: $white;
      border-color: $color-dark-grey;
    }
  }

  &.button-very-dark-grey {
    color: $white;
    border-color: $color-very-dark-grey;
    background-color: $color-very-dark-grey;

    &:visited {
      color: $white;
      border-color: $color-very-dark-grey;
    }
  }


  // Hovers separate so they can be mixed and matched with the non-hover states
  &.button-hover-color-light-grey {
    &:hover,
    &:active {
      color: $color-light-grey;
    }
  }

  &.button-hover-color-dark-grey-bg-white {
    &:hover,
    &:active {
      color: $color-dark-grey;
      background-color: $white;
      border-color: $white;
    }
  }

  &.button-hover-white-bg-light-grey {
    &:hover,
    &:active {
      color: $white;
      background-color: $color-light-grey;
      border-color: $color-light-grey;
    }
  }

  &.button-hover-white-bg-dark-grey {
    &:hover,
    &:active {
      color: $white;
      background-color: $color-dark-grey;
      border-color: $color-dark-grey;
    }
  }

  &.button-hover-bg-very-dark {
    &:hover,
    &:active {
      color: $white;
      background-color: $color-very-dark-grey;
      border-color: $color-very-dark-grey;
    }
  }

  &.button-hover-bg-black {
    &:hover,
    &:active {
      color: $white;
      background-color: $color-black;
      border-color: $color-black;
    }
  }

  &.button-hover-color-aqua {
    &:hover,
    &:active {
      color: $color-aqua;
    }
  }
}

input:not([type="submit"]),
textarea {
  width: 100%;
  border: none;
  background-color: $white;
  padding: ($base-spacing/4) ($base-spacing/2);
}

.text-orange {
  color: $color-orange;
}

.text-turquoise {
  color: $color-turquoise;
}
.none {
  display: none;
}

/*
.prototype-animate-skew{
  animation: skew 3s infinite ease-out;
  transform: skew(0, 0);
}

@keyframes skew {
  0% {
    transform: skew(10deg, 0);
  }
  20% {
    transform: skew(5deg, 10deg);
  }
  40% {
    transform: skew(0deg, 4deg);
  }
  60% {
    transform: skew(-10deg, -10deg);
  }
  80% {
    transform: skew(0deg, -10deg);
  }
  100% {
    transform: skew(0deg, 0deg);
  }
}*/

.external-link-notification {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  padding: $base-spacing;
  text-align: center;
  justify-content: center;
  z-index: 10000000;
  background: rgba(0,0,0,.7);
  top: 0;
  overflow: auto;
  @include small {
    padding: 12px;
  }
  &.active {
    display: flex;
  }
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    width: 23px;
    height: 23px;
  }
  .markdown-container {
    margin-bottom: $base-spacing * 2;
    font-family: $title-font;
  }
  .content {
    width: 80%;
    max-width: 970px;
    position: relative;
    background: #fff;
    margin: auto;
    padding: $base-spacing * 3 $base-spacing;
    justify-content: center;
    @include small {
      width: 100%;
    }
    a {
      @include button(secondary, medium);
      margin-right: $base-spacing;
      
      @include small {
        margin-bottom: $base-spacing;
      }
    }
    button {
      @include button(white, medium);
      @include medium {
        width: auto
      }
    }
  }
}