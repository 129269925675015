.main-content-section {
	@include section_padding();
	padding-top: 0;
	h2 {
		font-size: $font-size-header-small;
		margin: $base-spacing 0;
		color: $text;
		&:first-of-type {
			margin-top: 0; // might need to remove this
		}
	}
	h3 {
		font-size: 1.3rem;
		text-decoration: underline;
	}
	h4 {
		font-style: italic;
		font-size: 1.3rem;
	}
	a {
		text-decoration: underline;
	}
	.downloads-container {
		p {
			font-size: $font-size-body-small;
		}
		svg {
			width: 20px;
			margin-right: 8px;
			transform: translateY(5px);
		}
	}
}

