.footer-container{
  background-color: $primary-colour;
  color: $primary-colour-text-light;
  font-family: $title-font;
  .inner-wrapper{
    .footer-sponsored-by{
      padding-bottom: $base-spacing/2;
      @include medium{
        text-align: center;
        position: relative;
        left: -100px;
      }
    }
    .follow-container {
      text-align: left;
      min-width: 250px;
      color: $primary-colour-text-light;
      @include medium {
        text-align: center;
      }
      p {
        margin-bottom: $base-spacing;
        font-size: $font-size-body-small;
      }
      .social-button {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        display: inline-block;
        &.social-button-linkedin {
          .outer_bDW, .border_2yy {
            fill: #0077b5;
          }
          &:hover {
            .outer_bDW, .border_2yy {
              fill: #0099e8;
            }
          }
        }
        &.social-button-facebook {
          .outer_bDW, .border_2yy {
            fill: #3b5999;
          }
          &:hover {
            .outer_bDW, .border_2yy {
              fill: #4d70ba;
            }
          }
        }
        &.social-button-youtube {
          .outer_bDW, .border_2yy {
            fill: #cd201f;
          }
          &:hover {
            .outer_bDW, .border_2yy {
              fill: #e23e3d;
            }
          }
        }
      }
    }
    .footer-menu{
      display: flex;
      justify-content: space-between;
      position: relative;
      padding: $base-spacing 0;
      @include medium{
        flex-direction: column;
      }
      .footer-logo{
        grid-area: logo;
        align-self: center;
        svg{
          width: 100%;
          max-width: 270px;
          height: auto;
        }
      }
      
    }
    .footer-copyright-container{
      display: grid;
      grid-template-columns: 1fr 1fr;
      .footer-copyright-left{
        text-align: left;
      }
      .footer-copyright-right{
        text-align: right;
      }
    }
  }
}

.footer-menu-ul{
  border-top: 1px solid $white-xtransparent;
  position: relative;
  padding: $base-spacing / 2 0;
  font-size: $font-size-body-small;
  ul {
    display: flex; 
    margin: 0;
    justify-content: center;
    @include medium {
      flex-direction: column;
    }
    li {
      margin: 0;
      margin-right: $base-spacing;
      @include medium {
        text-align: center;
        margin: $base-spacing / 2 0;
      }
      a {
        font-size: $font-size-body-small;
        &:hover {
          color: $primary-colour-text;
        }
      }
    }
    .copyright {
      position: absolute;
      right: 0;
      font-size: $font-size-body-small;
      color: $primary-colour-text-light;
      @include medium {
        position: static;
      }
    }
  }
  a {
    color: $primary-colour-text-light;
  }
}