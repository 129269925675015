.banner-header-section {
	@include section_padding();
	.container {
		
	}
	h1 {
		font-size: 4.7rem;
		margin-bottom: $base-spacing / 2;
	}
	.subtitle {
		font-size: $font-sub-header;
		margin-bottom: 20px;
	}
	.link-container {
		margin-top: $base-spacing * 2;
		a {
			margin-right: $base-spacing / 2;
		}
	}
}

.about-content {
	.introduction {
		margin: $base-spacing 0 $base-spacing * 3 0;
		@include small {
			margin-bottom: $base-spacing;
		}
	}
	.pronunciation-container{
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: $base-spacing * 2;
		margin-bottom: $base-spacing * 2;
		@include small {
			grid-template-columns: 1fr;
			grid-gap: $base-spacing;
			margin-bottom: $base-spacing;
			text-align: center;
		}
		img {
			max-width: 400px;
			@include small {
				margin: auto;
			}
		}
		.phonetics-type {
			p {
				font-size: $font-size-body-large;
				font-weight: 700;
			}
			.name {
				color: $text-dark;
			}
		}
	}
	.sound-clip-intro{
		font-size: $font-size-header-small;
	}
	.quotes {
		width: 50px;
		margin: $base-spacing 0;
	}
}