@mixin xsmall {
  @media (max-width: #{$size-xsmall}) {
    @content;
  }
}

@mixin small {
  @media (max-width: #{$size-small}) {
    @content;
  }
}

@mixin largerthansmall {
  @media (min-width: #{$size-small + 1}) {
    @content;
  }
}

@mixin medium {
  @media (max-width: #{$size-medium}) {
    @content;
  }
}

@mixin largerthanmedium {
  @media (min-width: #{$size-medium + 1}) {
    @content;
  }
}

@mixin mediumexclusive {
  @media (max-width: #{$size-medium}) and (min-width: #{$size-small + 1}) {
    @content;
  }
}

@mixin large {
  @media (max-width: #{$size-large}) {
    @content;
  }
}

@mixin largeexclusive {
  @media (max-width: #{$size-large}) and (min-width: #{$size-medium + 1}) {
    @content;
  }
}

@mixin extralarge {
  @media (max-width: #{$size-extralarge}) {
    @content;
  }
}

@mixin extralargeexclusive {
  @media (max-width: #{$size-extralarge}) and (min-width: #{$size-large + 1}) {
    @content;
  }
}

@mixin extralargeplus {
  @media (min-width: #{$size-extralarge + 1}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$size-medium}) and (max-height: #{$size-medium}) {
    @content;
  }
}

@mixin prefix($property, $value, $prefixes: ()) {
  @each $prefix in $prefixes {
    #{'-' + $prefix + '-' + $property}: $value;
  }
  #{$property}: $value;
}

@mixin subheader2() {
  font-size: $h2-very-small;
  font-weight: 500;
}

@mixin button($style, $size) {
  //font-size: $button-standard;
  display: inline-block;
  font-family: $text;
  @include prefix(user-select, none, ms webkit moz o);
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  border: 0;
  transition: all $animation-fade;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  @if $size == medium {
    padding: 18px 32px;
    font-size: $font-size-button;
  } @else if $size == large {
    padding: 16px 55px;
    font-size: $button-large;
  } @else if $size == xlarge {
    padding: 22px 70px;
    font-size: $button-large;
  }
  @if $style == primary {
    color: $primary-colour-text;
    background: $primary-colour;
    &:hover {
      background: $secondary-colour;
      color: $secondary-colour-text;
    }
  } @else if $style == secondary {
    color: $secondary-colour-text;
    background: $secondary-colour;
    background-image: linear-gradient(110deg,#27ceff,$secondary-colour);
    &:hover {
      background: $secondary-colour-dark-1;
    }
    &:active {
      background: $secondary-colour-dark-1;
    }
  } @else if $style == three {
    color: $colour-three-text;
    background: $colour-three;
    &:hover {
      background: $colour-two-dark-1;
    }
    &:active {
      background: $colour-two-dark-1;
    }
  } @else if $style == yellow {
    color: $white;
    background: $colour-yellow;
    &:hover {
      background: $colour-yellow-dark;
    }
    &:active {
      background: $colour-yellow-dark;
    }
  } @else if $style == white {
    color: $text;
    background: $white;
    outline: 2px solid $primary-colour;
    padding: 16px 32px;
    &:hover {
      background: $primary-colour;
      color: $primary-colour-text;
    }
    @include medium {
      width: calc(100% - 2px);
      margin: auto;
    }
  } @else if $style == dark {
    color: $white;
    background: $dark-background;
    &:hover {

    }
  }
  @include medium {
    min-width: inherit;
  }
}

@mixin form-input() {
  width: 100%;
  border: 1px solid $input-border;
  border-radius: $border-radius;
  padding: $base-spacing / 2;
  font-size: $p-small;
  font-family: $text-font;
  color: $text-dark;
  box-shadow: 2px 2px 5px rgba(215, 219, 224, 0.3);
}

@mixin border-button() {
  border: 1px solid $white;
  padding: 11px;
  border-radius: $border-radius;
  font-size: $a-standard;
  transition: all $hover-fade-standard;
  &:hover {
    color: $dark-background;
    background: $white;
  }
}

@mixin section_padding() {
  padding: $base-spacing * 2 $base-spacing;

  @include small {
    padding: $base-spacing * 2 $base-spacing / 2;
  }
}

@mixin menu_padding() {
  padding: $base-spacing / 2 $base-spacing;
  @include medium {
    padding: $base-spacing / 2 $base-spacing;
  }
  @include small {
    padding: $base-spacing / 2 $base-spacing / 2;
  }
}


@mixin section_padding_breadcrumb() {
  padding: $base-spacing * 1.5 $base-spacing * 2 0;
  @include large {
    padding: $base-spacing / 2 $base-spacing;
  }
  @include medium {

  }
  @include small {

  }
}

@mixin section_padding_short() {
  padding: 10px $base-spacing;
  @include large {
    padding: 10px $base-spacing / 2;
  }
  @include medium {
    padding: 10px $base-spacing;
  }
  @include small {
    padding: 10px $base-spacing / 2;
  }
}





