.contact-section {
	display: grid;
	grid-template-columns: 50% 50%;
	@include small {
		grid-template-columns: 1fr;
	}
	.image-container {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		min-height: 400px;
	}
	.form-container {
		padding: $base-spacing * 3;
		@include medium {
			padding: $base-spacing * 2;
		}
		@include medium {
			padding: $base-spacing;
		}
	}
	h1 {
		font-size: $font-size-header-medium;
	}
	label {
		color: $text-xdark;
		font-size: $font-size-body-small;
	}
	input[type="text"], input[type="email"], textarea, select {
		background: $light-background;
		margin-top: $base-spacing / 4;
		padding: 21px 38px;
		width: 100%;
		border: 2px solid $border-dark;
		font-size: $font-size-button;
		color: $text-xdark;
	}
	input[type="checkbox"] {
		width: 30px;
	}
	.wpcf7-acceptance {
		a {
			text-decoration: underline;
			color: $text-xdark;
		}
	}
	textarea {
		height: 150px;
	}
	input[type="submit"], .cf7-smart-grid.has-grid .wpcf7-submit {
		width: 100%;
		background: $secondary-colour;
		color: $secondary-colour-text;
		text-transform: uppercase;
		border: none;
		padding: $base-spacing / 2;
		font-size: $font-size-button;
		transition: all 0.3s;
		margin: $base-spacing 0;
		&:hover {
			background-color: $secondary-colour-dark-1;
		}
	}
}

.cf7-smart-grid.has-grid .wpcf7-form-control:not(img) {
    margin-bottom: 21px;
}
.acceptance-628 .cf7sg-popup {
	display: none;
}
.map-container {
	position: relative;
	.info {
		position: absolute;
		left: 10%;
		top: 50%;		
		background-color: $primary-colour;
		color: $primary-colour-text;
		padding: $base-spacing * 1.5;
		text-align: center;
		border-radius: 8px;
		box-shadow: 0 2px 7px 1px rgba(0,0,0,0.1);
		max-width: 370px;
		transform: translateY(-50%);
		@include large {
			right: 3%;
		}
		@include medium {
			position: static;
			transform: none;
			max-width: unset;
		}
		p {
			font-size: $font-size-body-small;
			margin-left: auto;
			margin-right: auto;
		}
		.subname {
			max-width: 160px;
		}
		.subname-close {
			margin-bottom: 0;
		}
		h2 {
			font-size: $font-size-header-medium;
			color: $primary-colour-text;
		}
		h3 {
			font-size: $font-size-header-small;
			color: $primary-colour-text;
		}
		a {
			text-decoration: underline;
			color: $primary-colour-text;
		}
	}

}

.mapboxgl-map {
	width: 100%;
	height:100vh;
	@include medium {
		height: 70vh;
	}
	.mapboxgl-popup-content {
		border-radius: 8px;
		box-shadow: 0 2px 7px 1px rgba(0,0,0,0.3);
		padding: 14px 10px;
		width: 260px;
		text-align: center;
		p {
			margin: 0;
		}
		.mapboxgl-popup-close-button {
			width: 20px;
			height: 20px;
			font-size: 22px;
		}
		.map-location {
			font-family: $title-font;
			font-weight: 400;
			font-size: 15px;
		}
		.location {
			font-size: 13px;
			font-family: $body-font;
		}	
	}
}

