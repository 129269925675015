.modal-container{
  text-align: center;
  padding: $base-spacing*2 $base-spacing;
  max-width: 90%;
  max-height: 90%;
  width: 850px;
  position: fixed;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  z-index: 10001;
  h1{
    font-weight: bold;
  }
  h2 {
    font-size: $font-size-header-small;
    color: $text-dark;
  }
  a{
    margin: $base-spacing/2 auto $base-spacing auto;
    border-radius: 0;
    display: block;
    background-color: $primary-colour;
    border: none;
    color: $primary-colour-text;
    padding: $base-spacing / 2;
    max-width: 200px;
    &:hover {
     background-color: $colour-three;
     color: $colour-three-text;
    }
  }
  .alt-button {
    background-color: $secondary-colour;
  }
}
.modal-underlay{
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: $white;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}