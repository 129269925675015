.blog-listings-container {
  padding: $base-spacing;
  padding-top: 0;
  color: $color-dark-grey;

  h1, h2 {
    text-align: center;
    width: 100%;
  }
  h2 {
    //font-size: $font-size-header-medium;
     text-align: left;
  }


    .blog-listings-content {
      text-align: center;
    }

    .blog-listing-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: $base-spacing;
      flex-wrap: wrap;
      text-align: left;
      align-content: center;
      margin-left: -$base-spacing/2;
      margin-right: -$base-spacing/2;
      justify-content: left;
      @include small {
        grid-template-columns: 1fr 1fr;
      }
      @include xsmall {
        grid-template-columns: 1fr;
      }

      .blog-listing-container-inner {
        text-align: center;
        flex: 0 0 33%;
        margin: 0 $base-spacing / 2 $base-spacing $base-spacing / 2;

        .blog-listing-container-inner--image {
          text-align: center;



          .blog-listing-container-inner--image--inner {
            text-align: center;
            overflow: hidden;
            height: 220px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
        
        .blog-listing-container-excerpt {
          text-align: left;
          p {
            font-size: $font-size-body-small;
          }
        }

        .blog-listing-container-inner--title {
          font-size: $font-size-header-small;
          text-align: left;
          color: $primary-colour;
          font-weight: bold;
          @include small{
            font-size: $font-size-header-small;
          }
          a {
            color: $primary-colour;
          }
        }

        .blog-listing-container-inner--excerpt {
          margin: $base-spacing/2 0;
        }

        .blog-listing-container-inner--title {
          margin: $base-spacing/2 0;
          font-family: $body-font;
          font-weight: 400;
          a {
            color: $text;
          }
        }

        .blog-listing-container-inner--read-more {
          margin-top: $base-spacing * 2;
          a {
            border: 2px solid $primary-colour;
            padding: $base-spacing / 2;
            font-size: $font-size-button;
            text-transform: uppercase;
          }
        }
    }

    .tool-pagination {
      text-align: center;
      margin: auto;
      border-top: 1px solid $color-dark-grey;

      ul {
        display: inline-block;

        li {
          display: inline-block;
          font-size: $font-size-sub-header;
          list-style: none;

          &:before {
            content: none;
          }
        }
      }

      .next {
        margin-left: $base-spacing;
      }

      .prev {
        margin-right: $base-spacing;
      }
    }
  }
}
.blog-date {
    text-align: left;
    font-family: $body-font;
    font-size: $font-size-body-small;
    font-weight: 700;
    margin: $base-spacing / 2 0;
  }
.congresses-section {
  table, td, tr, th {
    border: none;
  }
  tr {
    border-bottom: 1px solid $primary-colour-transparent;
  }
  td {
     padding: $base-spacing;
  }
  .congress-title {
    font-size: $font-size-header-small;
    font-weight: 700;
    font-family: 'Muli';
  }
  .learn-more {
    text-transform: uppercase;
    font-size: $font-size-button;
  }
  .congress-date {
    p {
      margin: 0;
    }
    .congress-number {
      font-size: $font-size-header-medium;
      font-weight: 700;
      font-family: 'Muli';
      margin-bottom: $base-spacing / 2;
    }
    .congress-month {
      font-size: $font-size-body-small;
    } 
  }
  .congress-further-info {
    text-align: center;
  }

}
