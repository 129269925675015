/**
  SPACING
 */
$base-spacing: 32px;


/**
  COLORS
 */

$text: rgb(128, 130, 133);
$text-dark: #555;
$text-xdark: #000;

$light-background: rgba(128, 130, 133, 0.12);
$overlay: rgba(36, 36, 36, 0.04);
$overlay-dark: rgba(49, 49, 49, 0.22);
$border-dark: #808285;

$white:#fff;
$color-black:#000;
$color-blue: #004894;
$color-aqua: #00A1D4;

$colour-yellow: #f9ba07;
$colour-yellow-dark: #d59f06;


$color-very-light-grey:#e9e9e9;
$color-light-grey:#ddd;
$color-dark-grey:#555;
$color-very-dark-grey:#222;

$color-orange-light: #ffbf71;
$color-orange: #f49e31;
$color-orange-dark: darken($color-orange, 20%);

$color-turquoise: #3dc0bb;
$color-turquoise-dark: darken($color-turquoise, 20%);

// Dark Blue
$primary-colour: #00408b;
$primary-colour-transparent: rgba(92, 6, 140, 0.3);
$primary-colour-xtransparent: rgba(92, 6, 140, 0.12);
$primary-colour-text: #fff;
$primary-colour-text-light: rgb(153,176,201);

// Light Blue
$secondary-colour: #00a8da;
$secondary-colour-text: #FFF;
$secondary-colour-dark-1: #0291bb;

// Green
$colour-three: #98c000;
$colour-three-text: $white;
$colour-two-dark-1: #a51890;

$primary-colour-text-alt: $colour-three;



$light-grey: rgba(128, 130, 133, 0.3);
$white-transparent: rgba(255, 255, 255, 0.95);
$white-xtransparent: rgba(255, 255, 255, 0.25);
/**
  ANIMATIONS
 */
$animation-fade:0.2s;


/**
  FONTS
 */
$font-header: '';
$font-size-header: 5rem; 
$font-size-header-medium: 3rem;
$font-size-header-mobile-medium: 3.3rem;
$font-size-header-small: 2rem;
$font-sub-header: 2rem; //
$font-size-sub-header:  23px;
$font-size-sub-header-medium:  19px;
$font-size-sub-header-small:  17px;
$font-body: '';
$font-size-body: 1.3rem; //
$font-size-body-large: 1.7rem;
$font-size-body-medium: 15px;
$font-size-body-small: 1.1rem;
$font-button: '';
$font-size-button: 1.5rem;
$font-size-button-medium: 0.9rem;
$font-size-button-small: 0.7rem;

$font-size-menu: 1.1rem;


/**
  SIZES
 */
$size-xsmall: 480px;
$size-small: 768px;
$size-medium: 1024px;
$size-large: 1400px;
$size-extralarge: 1980px;

$content-short: 770px;

/** FONTS */

$body-font: 'Verdana', sans-serif;
$title-font: 'Merriweather Sans', sans-serif;

$menu-font: $body-font;

$border-radius: 3px;