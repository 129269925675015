.logo-bar-container {
  @include menu_padding();
  overflow: visible;
  font-size: $font-size-menu;
  
  .inner-wrapper {
    display: grid;
  grid-template-columns: 300px 1fr;
  }
  li, a, ul, p {
     font-size: $font-size-menu;
  }
  .search-language-container {
    justify-content: flex-end;
    display: flex;
    @include small {
      display: none;
      position: absolute;
      flex-direction: column;
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
      top: 340px;
      z-index: 6000000;
    }
    &.active {
      @include small {
        display: block;
      }
    }
    .search-box, .language-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .language-box {
      min-width: 50px;
      margin: 0 $base-spacing / 2;
      position: relative;
      z-index: 100000;
      svg {
        width: 10px;
        fill: #808285;
      }
      button {
        border: none;
        background: none;
      }
      ul {
        > li {
          margin: auto;
          span {
            margin: 6px;
            transform: translateY(3px);
            display: inline-block;
            text-transform: uppercase;
          }
          img {
            transform: translateY(-5px);
            display: inline-block;
          }
        }
        ul {
          position: absolute;
          display: none;
          left: 10px;
          top: 70px;
        }
        &.active {
          ul {
            display: block;
            margin: 0;
            background: white;
            box-shadow: 0 5px 15px 0 rgba(0,0,0,.1);
            li {
              padding: 5px 10px;
              &:hover {
                background: $color-very-light-grey;
              }
            }
          }
        }
      }
    }
    .search-box {
      position: relative;
    }
    .search-input {
      border: 1px solid $light-grey;
      font-size: 1.4rem;
      width: 100%;
      max-width: 300px;
      font-weight: 400;
      padding-right: 40px;
      border-radius: 30px;
    }
    .search-icon {
      position: absolute;
      height: 16px;
      width: 16px;
      right: 10px;
      cursor: pointer;
      path {
        fill: rgb(128, 130, 133);
      }
    }
  }
}
nav {
  background-color: $primary-colour;
  color: $primary-colour-text;
  font-family: $menu-font;
  a {
    color: $primary-colour-text;
    font-size: $font-size-menu;
    text-transform: uppercase;
  }
}
.top-navigation-container {
  position: relative;
  z-index: 10000;
  position: relative;
  padding: 0;
  display: flex;
  justify-content: center;
  font-size: $font-size-menu;
  @include small {
    grid-template-columns: 80%;
  }
  > ul{
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    margin: 0 0 0 $base-spacing;
    @include small {
      position: absolute;
      z-index: 1000;
      background: $white-transparent;
      margin: 0;
      padding: $base-spacing;
      flex-direction: column;
      width: 100%;
      min-height: 100vh;
      display: none;
      &.active {
        justify-content: flex-start;
        display: flex;
      }
    }
    > li{
      padding-left: 0;
      margin-bottom: 0;
      display: inline-block;
      align-self: center;
      margin: 0 19px;
      position: relative;
      padding: 22px 0;
      @include small {
        color: $text;
      }
      button {
        position: absolute;
        right: -10px;
        height: 7px;
        width: 7px;
        background: none;
        border: none;
        top: 40%;
        transform: translateY(-50%);
        transition: all 0.3s;
        svg {
            fill: white;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
      }
      &.active {
        > ul {
          max-height: 300px;
          margin: $base-spacing 0;
         }
         button {
          transform: rotate(180deg) translateY(8px);
         }
        }
      &:before{
        content: none;
      }
      @include small {
        margin-bottom: $base-spacing / 2;
        text-align: center;
        padding: 0;
      }
      > a {
         padding-bottom: 10px;
         border-bottom: 2px solid transparent;
         position: relative;
         @include small {
          color: $text;
        }
      }
      &:hover {
        button {
          svg {
            fill: $colour-three;
          }
        }
        > a {
          border-bottom: 2px solid $primary-colour;
          color: $primary-colour-text-alt;
        }    
        > ul {
          display: block;
        }
      }
      > ul {
        @include largerthansmall {
          position: absolute;
          background: white;
          padding: 21px 37px;
          border-radius: $border-radius;
          display: none;
          margin: 0;
          opacity: 1;
          top: 50px;
          box-shadow: 0 5px 15px 0 rgba(0,0,0,.1);
        }
       
          
          button {
           @include small {
            transform: rotate(180deg) translateY(8px);
             }
          }
        a {
          
            color: $text;
           
        }
        @include small {
          max-height: 300px;
          margin: $base-spacing 0;
          overflow: hidden;
          color: $text;
          transition: all 0.5s;
        }
        > li {
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      &.large {
        > ul {
           min-width: 300px;
         }
      }
      &.medium {
        > ul {
           min-width: 200px;
         }
      }
    }
    li:before{
      content: none;
    }
  }

  .navigation-logo {
    grid-area: header-logo;
    align-items: center;
    display: grid;
    img {
      max-width: 450px;
    }
  }

  .logo-strapline{
    position: relative;
    font-weight: bold;
    color: $color-blue;
    top: -15px;
    left: 53px;
  }


}

.top-navigation-container {
  .navigation-menu {
    grid-area: main-nav;

    position: relative;
    z-index: 1000;
    display: grid;

    > ul {
      margin: 0;
      text-align: right;
      flex: auto;
      align-self: center;

      > li {
        width: auto;
        display: inline-block;
        text-align: center;
        position: relative;
        margin: 0 8px;

        > a {
          font-size: 12px;
          text-transform: uppercase;
          display: inline-block;
          padding: 16px 24px;
          transition: color $animation-fade, background-color $animation-fade;
          width: 100%;
        }

        &:last-of-type {
          margin: 0 0 0 8px;
        }

        &:hover {
          > a {
          }

          > ul {
            display: block;
            min-width: 200px;
            width: 100%;
            margin-left: 0;

            li {
              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(3),
              &:nth-child(4),
              &:nth-child(5) {
                a {
                  left: 0;
                }
              }
            }
          }
        }

        > ul {
          z-index: 100;
          transition: opacity 0.3s;
          display: none;
          position: absolute;
          padding-top: 3px;
          width: 100%;
          margin: 0;

          > li {
            display: block;
            width: calc(100% + 20px);
            padding: 0;
            position: relative;
            margin-left: -10px;
            margin-right: -10px;

            > a {
              display: inline-block;
              width: 100%;
              background-color: $color-orange;
              color: #fff;
              padding: 12px 20px;
              box-shadow: 0 2px 4px rgba(255, 255, 255, 0.3);

              &:hover {
                background-color: darken($color-orange, 10%);
              }
            }
          }
        }
      }
    }
  }
}

.top-navigation-container {
  &.collapse-menu {
    .navigation-logo {
      margin-bottom: 0;
    }

    padding: 0 $base-spacing;
    margin-bottom: 0;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-gap: 0;
    grid-template-areas:
                        "header-logo"
                        "main-nav";
    position: relative;

    .navigation-menu-burger {
      position: absolute;
      width: 50px;
      height: 50px;
      right: $base-spacing;
      top: 32px;


      #nav-icon3 {
        width: 100%;
        height: 45px;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;
      }

      #nav-icon3 span {
        display: block;
        position: absolute;
        height: 9px;
        width: 100%;
        background: $color-orange;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
      }


      /* Icon 3 */

      #nav-icon3 span:nth-child(1) {
        top: 0px;
      }

      #nav-icon3 span:nth-child(2), #nav-icon3 span:nth-child(3) {
        top: 18px;
      }

      #nav-icon3 span:nth-child(4) {
        top: 36px;
      }

      #nav-icon3.open span:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
      }

      #nav-icon3.open span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      #nav-icon3.open span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      #nav-icon3.open span:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
      }


    }

    .navigation-menu {
      margin-left: -74px;
      margin-right: -74px;
      overflow: hidden;
      background-color: $color-orange;
      display: none;
      margin-top: $base-spacing;

      > ul {
        display: block;

        > li {
          margin: 0;
          display: block;

          > a {
            color: #fff;
            padding: 14px 0;
          }

          > ul {
            display: block;
            position: relative;
            margin: 0 0 20px 0;
            background-color: darken($color-orange, 10%);

            width: 100%;

            > li {
              left: 0;

              a {
                border: none;
                background-color: darken($color-orange, 10%);
                color: #fff;
                box-shadow: none;
                border-radius: 0;
              }
            }
          }

          &:hover {
            > a {
              color: #fff;
            }

            > ul {
              > li {
                &:hover {
                  a {
                    color: #fff;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

}

.menu-toggle {
    display: none;
    position: absolute;
    top: -53px;
    right: 26px;
    transform: translateY(-50%);
    font-size: 2em;
    cursor: pointer;
    color: $primary-colour;
    @include prefix(user-select, none, ms webkit moz o);
    @include small {
      display: block;
    }
    .menu-image {
     width: 34px;
     height: 25px;
      background-image: url(../images/icons/menu.png);
      background-repeat: no-repeat;
      background-position: 2px -2px;
      background-size: 84%;
      display: inline-block;
      position: relative;
      top: 5px;
      &.dropped {
        background-position: 2px -30px;
      }
    }
    .menu-word {
       @include prefix(user-select, none, ms webkit moz o);
       z-index: 2500;
       position: relative;
       color:$primary-colour;
       font-size: 1.7rem;
       text-transform: uppercase;
    }
}

.nav-trigger {
  width: 30px;
  height: 22px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  display: inline-block;
  top: 5px;
  overflow: hidden;
}

.nav-trigger span {
  display: block;
  position: absolute;
  height: 2px;
  width: 50%;
  background: $primary-colour;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
  &:nth-child(even) {
     left: 50%;
     border-radius: 0 9px 9px 0;
  }
  &:nth-child(odd) {
     left:0px;
     border-radius: 9px 0 0 9px;
  }
}

.nav-trigger span:nth-child(1), .nav-trigger span:nth-child(2) {
  top: 0px;
}

.nav-trigger span:nth-child(3), .nav-trigger span:nth-child(4) {
  top: 8px;
}

.nav-trigger span:nth-child(5), .nav-trigger span:nth-child(6) {
  top: 16px;
}

.nav-trigger.is-active span:nth-child(1),.nav-trigger.is-active span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.nav-trigger.is-active span:nth-child(2),.nav-trigger.is-active span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.nav-trigger.is-active span:nth-child(1) {
  left: 2px;
  top: 4px;
}

.nav-trigger.is-active span:nth-child(2) {
  left: calc(50% - 5px);
  top: 4px;
}

.nav-trigger.is-active span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

.nav-trigger.is-active span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

.nav-trigger.is-active span:nth-child(5) {
  left: 2px;
  top: 12px;
}

.nav-trigger.is-active span:nth-child(6) {
  left: calc(50% - 5px);
  top: 12px;
}