.single-post-container {
  padding: $base-spacing 0;

  .page-constraint {
    .blog-post-breadcrumb{
      a{
        display: block;
        color: $color-blue;
        svg{
          fill: $color-blue;
          height: 10px;
          width:10px;
        }
        margin-bottom: $base-spacing/2;
      }
    }
    .article-h1 {
      margin-bottom: $base-spacing;
      color: $text;
      @include small {
        font-size: $font-size-header-mobile-medium;
      }
    }
    .blog-date {
      font-family: $title-font;
    }
    article {
      min-height: 300px;
      max-width: $content-short;
      margin: auto;

      .image-column {
        margin: 0 0 $base-spacing $base-spacing;
        min-height: 400px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        @include small {
          min-height: 200px;
        }
         @include xsmall {
          margin: 0;
        }
        img {
        }
      }

      a {
        color: $color-dark-grey;

        &:hover {
          color: $color-dark-grey;
        }
      }

      .article-column {
        p, li, a {
          font-size: $font-size-body-small;
        }
        h2 {
          color: $text;
          font-size: $font-size-header-small;
        }
        ul {
          margin: $base-spacing/2;
        }
      }

      .article-takehome {
        font-size: $font-size-sub-header;
        font-weight: 700;
        margin-top: $base-spacing;
        color: $color-blue;
      }
    }

    .references {
      margin-top: $base-spacing;

      a {
        color: $color-blue;

        &:hover {
          color: darken($color-blue, 20%);
        }
      }
    }
  }

  .next-prev-article-buttons-container {
    margin-top: $base-spacing;

    .prev-button-container {
      display: inline-block;
      padding-right: $base-spacing*2;

      a {
        color: $color-aqua;
        font-weight: bold;
        font-size: $font-size-sub-header;
        text-transform: uppercase;

        svg {
          height: 16px;
          width: 16px;

          polygon {
            fill: $color-aqua;
          }
        }
      }
    }

    .next-button-container {
      display: inline-block;

      a {
        color: $color-blue;
        font-weight: 900;
        font-size: $font-size-sub-header;
        text-transform: uppercase;

        svg {
          height: 16px;
          width: 16px;

          polygon {
            fill: $color-blue;
          }
        }
      }
    }

    @include small {
      display: block;
      width: 100%;
      text-align: center;
      .next-button-container,
      .prev-button-container {
        padding: $base-spacing/2;
        a {
          font-size: $font-size-sub-header-small;
        }
      }
    }
  }
}