.slider-section {
	overflow: hidden;
	position: relative;
	.container {
		position: relative;
		z-index: 60;
		@include medium {
			padding: $base-spacing;
		}
	}
	.slide {
		min-height: 80vh;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: right;
		h2 {
			color: $white;
		}
		p {
			color: $white;
		}
		.wrapper {
			display: flex;
			flex-direction: column;
			justify-content: center;
			min-height: 80vh;
			max-width: 550px;
			@include small {
				max-width: unset;
				text-align: center;
			}
			.container {
				padding-left: 100px;
				position: relative;
				@include small {
					padding-left: $base-spacing;
				}
			}
		}
		.dot {
			width: 70px;
			height: 70px;
			margin-bottom: $base-spacing;
			position: absolute;
			left: 0;
			top: 5px;
			@include small {
				position: static;
				margin: auto;
			}
		}
	}
	.slick-dotted.slick-slider {
		margin-bottom: 0;
	}
	.slick-dots {
		bottom: 25px;
	}
	.slick-dots li button::before {
		font-size: 11px;
	}
	.slick-dots li.slick-active button::before {
    	opacity: 1;
    	color: #808285;
	}
	.slick-arrow {
		z-index: 1000;
		width: 30px;
		height: 30px;
		background-size: contain;
		background-repeat: no-repeat;
		opacity: 0.5;
		@include small {
			opacity: 1;
			background-size: 80%;
			background-position: center;
			border-radius: 50%;
			background-color: $white-transparent;
		}
		&:hover {
			@include small {
				opacity: 1;
				background-size: 80%;
				background-position: center;
				border-radius: 50%;
				background-color: $white-transparent;
			}
		}
		&:before {
			content: unset;
		}
	}
	.slick-prev {
		left: 25px;
		background-image: url('/wp-content/themes/gedeonrichterch/custom/images/arrow-left.png');
		&:hover, &:focus {
			background-image: url('/wp-content/themes/gedeonrichterch/custom/images/arrow-left.png');
			color: unset;
		}
		@include small {
			left: 5px;
		}
	}
	.slick-next {
		right: 25px;
		background-image: url('/wp-content/themes/gedeonrichterch/custom/images/arrow-right.png');
		&:hover, &:focus {
			background-image: url('/wp-content/themes/gedeonrichterch/custom/images/arrow-right.png');
			color: unset;
		}
		@include small {
			right: 5px;
		}
	}
	
}
.button-blue {
	@include button(primary, medium);
}
.button-yellow {
	@include button(yellow, medium);
}
.button-lightblue {
	@include button(secondary, medium);
}
.button-green {
	@include button(three, medium);
}

.timeline-section {
	@include section_padding();
	font-family: $title-font;
	.button {
		@include button(secondary, medium);
	}
	.embed-container {
		display: grid;
		grid-template-columns: 50% 50%;
		grid-gap: $base-spacing * 2;
		@include small {
			grid-template-columns: 1fr;
			grid-gap: $base-spacing;
		}
		video {
			width: 100%;
			@include small {
				margin-bottom: $base-spacing;
			}
		}
	}
}
.timeline-container {
	font-family: $title-font;
	
	.entry-row {
		display: grid;
		grid-template-columns: 100px 1fr;
		grid-gap: $base-spacing / 2;
		margin-bottom: $base-spacing;
		&:nth-of-type(odd) {
			.entry-date {
				background: $primary-colour;
			}
		}
		&:nth-of-type(even) {
			.entry-date {
				background: $secondary-colour;
			}
		}
		&:last-of-type {
			.connector {
				display: none;

			}
		}
	}
	.entry-date {
		color: $primary-colour-text;
		border-radius: 50%;
		width: 100px;
		height: 100px;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;
		z-index: 10;
		font-family: $body-font;
		.connector {
			position: absolute;
			height: 110px;
			width: 4px;
			background-color: $secondary-colour;
			left: 50%;
			z-index: 1;
			bottom: -110px;
		}
		.date {
			font-size: 26px;
			word-break: break-all;
			max-width: 80px;
			margin: auto;
		}
	}
	.entry-text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		p {
			margin: 0;
		}
	}
}

.responsibility-section {
	background-position: right;
	background-size: cover;
	background-repeat: no-repeat;
	background-position-x: 69.06%;
	background-position-y: 51.27%;
	@include section_padding();
	position: relative;
	h2, p {
		color: white;
	}
	.inner-wrapper {
		position: relative;
		z-index: 60;
	}
	.wysiwyg-container {
		max-width: $content-short;
		> p > strong {
			font-size: $font-size-body;
		}
		p {
			font-size: $font-size-body-small;
		}	
	}
	.button {
		@include button(secondary, medium);
		margin-right: $base-spacing / 2;
	}
}

.expertise-section {
	@include section_padding();
	.expertise {
		margin: $base-spacing * 2 0;
		.image {
			border-radius:$border-radius;
			margin-bottom: $base-spacing;
		}
		.accordion {
			padding: $base-spacing / 2 0;
			overflow: hidden;
			transition: all $animation-fade;
			&.active {
				max-height: 270px;
				h3 {
					span {
						transform: rotate(270deg);
					}
				}
			}
			h3 {
				cursor: pointer;
				user-select: none;
				position: relative;
				span {
					position: absolute;
					right: 32px;
					width: 15px;
					height: 15px;
					background-image: url('/wp-content/themes/gedeonrichterch/custom/images/arrow-right.png');
					transform: rotate(90deg);
					background-size: contain;
					transition: all $animation-fade;
					opacity: 0.5;
					top: 7px;
				}
			}
			ul {
				padding: 0;
				margin-left: 20px;
				margin-bottom: 12px;
			}
			li {
				list-style-type: disc;
				margin: 8px 0;
				font-size: $font-size-body-small;
			}
		}
	}
	.expertise-standout {
		.image {
			min-height: 560px;
		}
	}
	.expertise-remainder {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: $base-spacing;
		@include medium {
			grid-template-columns: 1fr;
		}
		.image {
			min-height: 370px;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
		}
	}
	.further-info{
		text-align: center;
		font-size: $font-size-body-small;
	}
	.button {
		@include button(secondary, medium);
	}
}

.innovation-section {
	min-height: 80vh;
	@include section_padding();
	background-size: cover;
	background-repeat: no-repeat;
	@include small {
		padding: $base-spacing * 2 $base-spacing / 2;
	}
	.container {
		background: $primary-colour;
		color: $primary-colour-text;
		padding: 70px 45px;
		max-width: $content-short;
		border-radius: $border-radius;
		h2 {
			color: $primary-colour-text;
			font-size: $font-size-header-medium;
		}
		p {
			font-size: $font-size-body-small;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}

.news-section {
	@include section_padding();
	background-size: cover;
	background-repeat: no-repeat;
	background-position: right;
	@include medium {
		background: none;
	}
	h2 {
		margin-bottom: $base-spacing * 2;
	}
	.button {
		@include button(secondary, medium);
	}
}